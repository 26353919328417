<template>
  <v-card class="container">
    <v-tabs
      :key="index"
      background-color="#fff"
      show-arrows
      tag="li"
      :slider-color="color"
      :slider-size="size"
    >
      <v-tab
        class="letter-tab"
        active-class="letter-tab--active"
        v-for="(item, id) in letterPagination"
        :key="id"
        @click="$emit('updateLetter', item.letter.toLowerCase())"
      >
        {{ item.letter }}
      </v-tab>
    </v-tabs>
  </v-card>
</template>

<script>
  export default {
    name: 'letterPagination',
    props: {
      sliderSize: Number,
      sliderColor: String,
    },
    watch: {
      sliderSize(newValue) {
        this.size = newValue
        this.index++
      },
      sliderColor(newValue) {
        this.color = newValue
        this.index++
      },
    },
    data() {
      return {
        size: 0,
        color: '',
        index: 1,
        letterPagination: [
          {
            id: 1,
            letter: 'A',
          },
          {
            id: 2,
            letter: 'B',
          },
          {
            id: 3,
            letter: 'C',
          },
          {
            id: 4,
            letter: 'D',
          },
          {
            id: 5,
            letter: 'E',
          },
          {
            id: 6,
            letter: 'F',
          },
          {
            id: 7,
            letter: 'G',
          },
          {
            id: 8,
            letter: 'H',
          },
          {
            id: 9,
            letter: 'I',
          },
          {
            id: 10,
            letter: 'J',
          },
          {
            id: 11,
            letter: 'K',
          },
          {
            id: 12,
            letter: 'L',
          },
          {
            id: 13,
            letter: 'M',
          },
          {
            id: 14,
            letter: 'N',
          },
          {
            id: 15,
            letter: 'Ñ',
          },
          {
            id: 16,
            letter: 'O',
          },
          {
            id: 17,
            letter: 'P',
          },
          {
            id: 18,
            letter: 'Q',
          },
          {
            id: 19,
            letter: 'R',
          },
          {
            id: 20,
            letter: 'S',
          },
          {
            id: 21,
            letter: 'T',
          },
          {
            id: 22,
            letter: 'U',
          },
          {
            id: 23,
            letter: 'V',
          },
          {
            id: 24,
            letter: 'W',
          },
          {
            id: 25,
            letter: 'X',
          },
          {
            id: 26,
            letter: 'Y',
          },
          {
            id: 27,
            letter: 'Z',
          },
        ],
      }
    },

    methods: {},
  }
</script>

<style lang="scss" scoped>
  .container.container.container {
    box-shadow: none;
    // margin-bottom: 10px;
  }
  .letter-tab {
    min-width: 60px;
    justify-content: center;
    font-size: initial;
    color: rgba(0, 0, 0, 0.54);
  }
</style>

<template>
  <div>
    <h2 style="font-size: 1.5rem; text-align: center; margin: 20px 0">
      Base de Conocimientos
    </h2>
    <carousel-3d
      :controls-visible="true"
      :width="400"
      :space="200"
      :height="300"
      :perspective="50"
      :scale="350"
      :animation-speed="1000"
      :display="7"
      :clickable="true"
    >
      <slide v-for="(item, index) in videos" :index="item.index" :key="index">
        <iframe
          :src="item.src"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          :title="item.title"
        ></iframe
      ></slide>
    </carousel-3d>

    <swiper ref="knowledgeSwiper" :options="knowledgeOptions">
      <swiper-slide>Slide 1</swiper-slide>
      <swiper-slide>Slide 2</swiper-slide>
      <swiper-slide>Slide 3</swiper-slide>
      <swiper-slide>Slide 4</swiper-slide>
      <swiper-slide>Slide 5</swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>
<script>
  export default {
    name: 'WikiKnowledge',
    data() {
      return {
        knowledgeOptions: {},
      }
    },
    props: {
      videos: Array,
    },
  }
</script>

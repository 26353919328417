<template>
  <div class="main-wrapper">
    <video-background
      :src="'/wiki-background.webm'"
      :poster="'/Snapshot_3.png'"
      :autoplay="true"
      :sources="[
        {
          src: '/wiki-background-2.mp4',
          res: 800,
          autoplay: true,
          poster: '/vlcsnap-00003.png',
        },
      ]"
      style="max-height: 400px; height: 100vh"
      overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)"
    >
      <h1 style="align-self: center; color: white">Conocimiento para el Exito. </h1>
      <v-bottom-navigation
        grow
        flat
        color="white"
        style="
          position: absolute;
          bottom: 0;
          background-color: transparent;
          color: #fff;
          padding-top: 0px;
        "
      >
        <v-btn href="#glosary" value="glosary">
          <span style="color: white">Glosario</span>

          <v-icon>mdi-history</v-icon>
        </v-btn>

        <v-btn href="#conocimientos" value="knowledge">
          <span style="color: white">Conocimientos</span>

          <v-icon>mdi-heart</v-icon>
        </v-btn>

        <v-btn value="statistics">
          <span style="color: white">Estadisticas</span>

          <v-icon>mdi-map-marker</v-icon>
        </v-btn>
        <v-btn value="podcasts">
          <span style="color: white">Podcast</span>

          <v-icon>mdi-map-marker</v-icon>
        </v-btn>
        <v-btn value="forms">
          <span style="color: white">Encuestas</span>

          <v-icon>mdi-map-marker</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </video-background>
    <!-- El componente de abajo tiene un margin-top para observarlo mientras se a;ade el resto de componentes  -->

    <!-- <v-bottom-navigation grow style="margin-top: 100px">
      <v-btn value="recent">
        <a href="#glosary">
          <span style="color: white">Glosario</span>

          <v-icon>mdi-history</v-icon>
        </a>
      </v-btn>

      <v-btn value="favorites">
        <a href="#conocimientos">
          <span style="color: white">Conocimientos</span>

          <v-icon>mdi-heart</v-icon>
        </a>
      </v-btn>

      <v-btn value="nearby">
        <span style="color: white">Estadisticas</span>

        <v-icon>mdi-map-marker</v-icon>
      </v-btn>
      <v-btn value="nearby">
        <span style="color: white">Podcast</span>

        <v-icon>mdi-map-marker</v-icon>
      </v-btn>
      <v-btn value="nearby">
        <span style="color: white">Encuestas</span>

        <v-icon>mdi-map-marker</v-icon>
      </v-btn>
    </v-bottom-navigation> -->
    <!-------- / Nav Section -------->

    <!-------- Video Carrousel Section -------->
    <knowledge
      id="conocimientos"
      style="padding-top: 80px; margin: 0 50px"
      :videos="videoCarousel"
    ></knowledge>

    <!-------- Glosary Section -------->
    <glosary
      id="glosary"
      style="margin-bottom: 20px; padding-top: 80px; height: calc(100vh - 70px)"
    ></glosary>
  </div>
</template>
<script>
  import WikiGlosary from '@/components/globalFrontendComponents/WikiGlosary'
  import WikiKnowledge from '@/components/globalFrontendComponents/WikiKnowledge'

  export default {
    data() {
      return {
        name: 'WikiFranchises',

        videoCarousel: [
          {
            index: 0,
            src:
              'https://player.vimeo.com/video/599872992?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=af75a1057a',
            title: 'XPO STAND',
          },
          {
            index: 1,
            src:
              'https://player.vimeo.com/video/599872992?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=af75a1057a',
            title: 'XPO STAND',
          },
          {
            index: 2,
            src:
              'https://player.vimeo.com/video/599872992?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=af75a1057a',
            title: 'XPO STAND',
          },
          {
            index: 3,
            src:
              'https://player.vimeo.com/video/599872992?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=af75a1057a',
            title: 'XPO STAND',
          },
          {
            index: 4,
            src:
              'https://player.vimeo.com/video/599872992?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=af75a1057a',
            title: 'XPO STAND',
          },
          {
            index: 5,
            src:
              'https://player.vimeo.com/video/599872992?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=af75a1057a',
            title: 'XPO STAND',
          },
          {
            index: 6,
            src:
              'https://player.vimeo.com/video/599872992?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=af75a1057a',
            title: 'XPO STAND',
          },
          {
            index: 7,
            src:
              'https://player.vimeo.com/video/599872992?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=af75a1057a',
            title: 'XPO STAND',
          },
          {
            index: 8,
            src:
              'https://player.vimeo.com/video/599872992?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=af75a1057a',
            title: 'XPO STAND',
          },
        ],
      }
    },
    components: {
      Glosary: WikiGlosary,
      Knowledge: WikiKnowledge,
    },
  }
</script>
<style lang="scss"></style>
